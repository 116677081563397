import React, { useEffect, useRef } from 'react';
import getCategory from '../utils/getCategory';

const Sidebar = ({ categories, vehicles }) => {
    const closeMenuRef = useRef();
    const categoryMenuRef = useRef();
    const vehiclesMenuRef = useRef();

    const handleWindowResize = () => {
        const isMobile = window.innerWidth < 1025;
        const menus = [categoryMenuRef.current, vehiclesMenuRef.current];
        const lastActive = document.querySelector(".active-widget");

        if (lastActive) lastActive.classList.remove("active-widget");

        menus.forEach(menu => isMobile ? menu.classList.add("mobile") : menu.classList.remove("mobile"));
    };

    const handleActiveMenu = menu => {
        const lastActive = document.querySelector(".active-widget");

        if (lastActive) lastActive.classList.remove("active-widget");
        menu.classList.add("active-widget");
    };

    const handleCloseMenu = () => {
        const lastActive = document.querySelector(".active-widget");
        if (lastActive) lastActive.classList.remove("active-widget");
    };

    const handleSelection = (current, type, group, selection = null) => {
        console.log(`l-${selection}`, type, group, 8);

        const vehicleGrid = document.querySelector(".vehicle--grid");
        const cards = vehicleGrid.querySelectorAll('.card');
        const lastActive = document.querySelector(".active-widget");

        if (lastActive) lastActive.classList.remove("active-widget");

        document.querySelectorAll('input').forEach(item => item.checked = (item === current.parentNode.querySelector('input')));
        cards.forEach(item => item.classList.remove("active-card"));

        if (type === 'all') {
            cards.forEach(item => item.classList.add("active-card"));
        } else {
            const targets = document.querySelectorAll(`[data-${group}-id="${selection}"]`);
            targets.forEach(item => item.classList.add("active-card"));
        }
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    // useEffect(() => {
    //     const handleMessage = (event) => {
    //         console.log('Mensaje recibido:', event.data, 'Origen:', event.origin);
    //         var dataCampaign = JSON.parse(event.data)
    //         console.log("Decrypt", dataCampaign);
    //         try {
    //             //let getOrigin = 'https://es-pr.dark.prod.heliosnissan.net'; //Staging
    //             let getOrigin = 'https://es.nissan.pr/'; // Production
    //             if (event.origin === getOrigin) {

    //                 const urlParams = new URLSearchParams(event.data);
    //                 console.log(urlParams);
    //                 if (urlParams.has('campaign')) {
    //                     const campaign = urlParams.get('campaign');
    //                     console.log(campaign);
    //                     if (campaign === '8') {
    //                         const categoryElement = document.getElementById(`l-8`);
    //                         if (categoryElement) {
    //                             handleSelection(categoryElement, "list", "category", 8);
    //                         }
    //                     }
    //                 }
    //             } else {
    //                 console.warn('Mensaje recibido de un origen no permitido:', event.origin);
    //             }
    //         } catch (error) {
    //             console.error('Mensaje inválido recibido:', error);
    //         }
    //     };


    //     window.addEventListener('message', handleMessage);
    //     return () => window.removeEventListener('message', handleMessage);
    // }, []);

    useEffect(() => {
        const handleMessage = (event) => {
            console.log('Mensaje recibido:', event.data, 'Origen:', event.origin);
            try {
                //let getOrigin = 'https://es-pr.dark.prod.heliosnissan.net'; // Staging
                let getOrigin = 'https://es.nissan.pr'; // Production
                if (event.origin === getOrigin) {
                    // Analizar el mensaje como JSON
                    const message = JSON.parse(event.data);
                    console.log('Mensaje parseado:', message);

                    if (message.campaign) {
                        const campaign = message.campaign;
                        console.log('Campaign detectado:', campaign);
                        if (campaign === '8') {
                            const categoryElement = document.getElementById(`l-8`);
                            if (categoryElement) {
                                handleSelection(categoryElement, "list", "category", 8);
                            }
                        }
                    }
                } else {
                    console.warn('Mensaje recibido de un origen no permitido:', event.origin);
                }
            } catch (error) {
                console.error('Mensaje inválido recibido:', error);
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, []);



    return (
        <aside className="sidebar">
            <div className="mobile-menu">
                <button onClick={() => handleActiveMenu(categoryMenuRef.current)} className="trigger categories">Categorías</button>
                <button onClick={() => handleActiveMenu(vehiclesMenuRef.current)} className="trigger models">Modelos</button>
            </div>

            <div ref={categoryMenuRef} className="widget categories">
                <div className="widget--mobile-header">
                    <h4>Filtrar por:</h4>
                    <button className="widget--close-btn" ref={closeMenuRef} onClick={handleCloseMenu}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x">
                            <path d="M18 6 6 18" /><path d="m6 6 12 12" />
                        </svg>
                    </button>
                </div>

                <h3>Categorías</h3>
                <div className="widget--list">
                    <label
                        htmlFor="todos-categoria"
                        onClick={e => handleSelection(e.target, 'all')}
                        className="widget--list-item">
                        <input id="todos-categoria" type="radio" value="all" className="peer" defaultChecked={true} />
                        <span className="peer-checked:text-black peer-checked:translate-x-2">Todos</span>
                    </label>
                    {categories.map((category, key) =>
                        <label
                            key={key}
                            htmlFor={getCategory(category)}
                            id={`l-${category}`}
                            onClick={e => handleSelection(e.target, "list", "category", category)}
                            className="widget--list-item">
                            <input id={`v-${getCategory(category)}`} type="radio" value={category} className="peer" />
                            <span className="peer-checked:text-black peer-checked:translate-x-2">{getCategory(category)}</span>
                        </label>
                    )}
                </div>
            </div>

            <div ref={vehiclesMenuRef} className="widget vehicles">
                <div className="widget--mobile-header">
                    <h4>Filtrar por:</h4>
                    <button className="widget--close-btn" ref={closeMenuRef} onClick={handleCloseMenu}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x">
                            <path d="M18 6 6 18" /><path d="m6 6 12 12" />
                        </svg>
                    </button>
                </div>

                <h3>Modelos</h3>
                <div id="models" className="widget--list">
                    <label
                        htmlFor="todos-vehiculos"
                        onClick={e => handleSelection(e.target, 'all')}
                        className="widget--list-item">
                        <input id="todos-vehiculos" type="radio" value="all" className="peer" defaultChecked={true} />
                        <span className="peer-checked:text-black peer-checked:translate-x-2">Todos</span>
                    </label>
                    {vehicles.map((vehicle, key) =>
                        <label
                            key={key}
                            htmlFor={(vehicle.car_model.id)}
                            id={`l${vehicle.car_model.id}`}
                            onClick={e => handleSelection(e.target, "list", "vehicle", vehicle.car_model.id)}
                            className="widget--list-item">
                            <input id={`v-${vehicle.car_model.id}`} type="radio" value={vehicle.car_model.id} className="peer" />
                            <span className="peer-checked:text-black peer-checked:translate-x-2">{(vehicle.category === 8 ? vehicle.car_model.name + " " + vehicle.apr : vehicle.car_model.name)} </span>
                        </label>
                    )}
                </div>
            </div>
        </aside>
    );
};

export default Sidebar;
